import { useState } from 'react';
import moment from 'moment-timezone';


export function useTextInput(options) {
    const [state, setState] = useState({
        value: options?.defaultValue ?? '',
        error: false,
        helperText: '',
        placeholder: options?.placeholder,
        disabled: options?.disabled ?? false
    });

    const onChange = (e) => {
        let newValue = e.target.value;

        if (options?.noSpaces) {
            newValue = e.target.value.replace(/ /g, '')
        }

        if (options?.format === 'alphanumeric') {
            newValue = e.target.value.replace(/[^0-9a-zA-Z]/g, '');
        }
        if (options?.format === 'numeric') {
            newValue = e.target.value.replace(/[^0-9]/g, '');
        }

        if (options?.maxLength) {
            newValue = newValue.slice(0, options.maxLength);
        }

        setState(s => ({ ...s, value: newValue, error: false, helperText: '' }));
    };

    const setValue = (value) => {
        setState(s => ({ ...s, value, error: false, helperText: '' }));
    };

    const setError = (message) => {
        setState(s => ({ ...s, error: true, helperText: message }));
    };

    const setPlaceholder = (text) => {
        setState(s => ({ ...s, placeholder: text }));
    };

    const setDisabled = (disabled) => {
        disabled === true
            ? setState(s => ({ ...s, value: '', disabled, helperText: '', error: false }))
            : setState(s => ({ ...s, disabled }));
    };

    return {
        value: state.value,
        setValue: setValue,
        setError: setError,
        setDisabled: setDisabled,
        setPlaceholder: setPlaceholder,
        formProps: {
            value: state.value,
            onChange: onChange,
            error: state.error,
            helperText: state.helperText,
            placeholder: state.placeholder,
            disabled: state.disabled
        }
    };
}


export function useNumberInput(options) {
    const [state, setState] = useState({
        value: typeof options?.defaultValue === 'number' ? String(options.defaultValue) : '',
        error: false,
        helperText: '',
        placeholder: options?.placeholder,
        disabled: options?.disabled ?? false
    });

    const onChange = (e) => {
        let newValue = e.target.value;

        if (options?.integerOnly) {
            // Integers only
            newValue = newValue.replace(/[^0-9]/g, '')
        }
        else {
            // Decimal places are allowed
            const numArray = newValue.replace(/[^0-9.]/g, '').split('.');
            newValue = numArray.length > 1
                ? numArray[0] + '.' + numArray[1]
                : numArray[0];

            // Max # of decimal places defined
            if (typeof options?.decimalPlaces === 'number') {
                if (numArray[1] && numArray[1].length > options.decimalPlaces) {
                    newValue = numArray[0] + '.' + numArray[1].slice(0, options.decimalPlaces);
                }
            }
        }

        if (options?.format === 'currency') {
            // Add 0 if there is nothing before the decimal point
            if (newValue.includes('.') && newValue.split('.')[0] === '') {
                newValue = '0' + newValue;
            }
            // Check if newValue is a number. Remove extra digits after decimal point
            if (!isNaN(newValue) || newValue === '') {
                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                    newValue = newValue.slice(0, newValue.indexOf('.') + 3);
                }
            }
        }

        if (options?.maxLength) {
            newValue = newValue.slice(0, options.maxLength);
        }

        setState(s => ({ ...s, value: newValue, error: false, helperText: '' }));
    };

    const setValue = (value) => {
        setState(s => ({ ...s, value, error: false, helperText: '' }));
    };

    const setError = (message) => {
        setState(s => ({ ...s, error: true, helperText: message }));
    };

    const setPlaceholder = (text) => {
        setState(s => ({ ...s, placeholder: text }));
    };

    const setDisabled = (disabled) => {
        disabled === true
            ? setState(s => ({ ...s, value: '', disabled, helperText: '', error: false }))
            : setState(s => ({ ...s, disabled }));
        
    };

    return {
        value: state.value,
        setValue: setValue,
        setError: setError,
        setPlaceholder: setPlaceholder,
        setDisabled: setDisabled,
        formProps: {
            value: state.value,
            onChange: onChange,
            error: state.error,
            helperText: state.helperText,
            placeholder: state.placeholder,
            disabled: state.disabled
        }
    };
}


export function useDatePicker(options) {
    const [state, setState] = useState({
        value: options?.defaultValue ? moment(options.defaultValue) : moment(),
        error: false,
        helperText: ''
    });

    const onChange = (value) => {
        let error = Boolean(state.error);
        let helperText = String(state.helperText);

        if (error === true && moment(value).isValid()) {
            // Clear error message if user enters a valid date
            error = false;
            helperText = '';
        }

        // Update value and clear error message on user input
        setState({
            ...state,
            value: moment(value),
            error: error,
            helperText: helperText
        });
    };

    const setError = (error, message) => {
        setState({ ...state, error: error, helperText: message || '' });
    };

    const onBlur = () => {
        // Validate input when user clicks away from the input
        if (state.value.isValid() === false) {
            setState({ ...state, error: true, helperText: 'Invalid date format' });
        }
    };

    return {
        value: state.value,
        setError: setError,
        formProps: {
            value: state.value,
            onChange: onChange,
            onBlur: onBlur,
            error: state.error,
            helperText: state.helperText
        }
    };
}


export function useToggle(options) {
    const [state, setState] = useState({
        checked: options?.defaultValue ?? false,
        disabled: options?.disabled ?? false
    });

    const onChange = (e) => {
        setState(s => ({ ...s, checked: e.target.checked }));
    };

    const setValue = (checked) => {
        setState(s => ({ ...s, checked }));
    };

    const setDisabled = (disabled) => {
        setState(s => ({ ...s, disabled }))
    };

    return {
        checked: state.checked,
        setValue: setValue,
        setDisabled: setDisabled,
        formProps: {
            checked: state.checked,
            onChange: onChange,
            disabled: state.disabled
        }
    };
}