import React, { useState } from 'react';

// Material UI
import { styled, Box, Paper, Button, useMediaQuery, Typography, useTheme, Divider, IconButton } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SwapVertIcon from '@material-ui/icons/SwapVert';

// Components
import { ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import { ActionButton } from 'components/ui/buttons';
import ProductTitle from 'components/products/ProductTitle';
import BundleCreateModal from 'components/bundles/BundleCreateModal';
import BundleEditModal from 'components/bundles/BundleEditModal';
import Tooltip from 'components/popups/Tooltip';

// Utility
import { useAuth } from 'context/auth';
import ReactGA from 'react-ga'
import { useEvent } from 'context/event';
import { formatPrice } from 'utility/numbers';


export default function BundleList(props) {
    const { eventId, enableBundles, onUpdate, bundles, enableSort, onBundleSort, toggleBundleSorting, onBundleSortSubmit } = props;

    // State
    const [modal, setModal] = useState({
        open: false,
        bundleId: null
    });


    // Utility
    const event = useEvent();
    const { auth } = useAuth();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));

    const hostId = auth.hostId;


    const handleModalOpen = () => setModal({ open: true, bundleId: null });
    const handleModalClose = () => setModal({ open: false, bundleId: null });


    function handleBundleSelect(id) {
        ReactGA.event({ category: "Tickets", action: 'View Bundle ', label: `${hostId}` });
        setModal({ open: true, bundleId: id })
    }
    
    function handleBundleCreate() {
        ReactGA.event({ category: "Tickets", action: 'Create Bundle ', label: `${hostId}` });
        setModal({ open: false, bundleId: null });
        onUpdate();
    }
   
    function handleBundleEdit() {
        ReactGA.event({ category: "Tickets", action: 'Edit Bundle ', label: `${hostId}` });
        setModal({ open: false, bundleId: null });
        onUpdate();
    }


    return (
        <>
            <ControlBar>
                <ControlBarHeader>Bundles</ControlBarHeader>
                <ControlBarButton disabled={enableBundles !== true} onClick={handleModalOpen}>New Bundle</ControlBarButton>
            </ControlBar>

            {mobileView === true && (
                <MobileBundleList
                    event={event}
                    bundles={bundles}
                    onBundleSelect={handleBundleSelect}
                    onBundleSort={onBundleSort}
                    enableSort={enableSort}
                    toggleBundleSorting={toggleBundleSorting}
                    onBundleSortSubmit={onBundleSortSubmit}
                />
            )}

            {mobileView === false && (
                <DesktopBundleList
                    event={event}
                    bundles={bundles}
                    onBundleSelect={handleBundleSelect}
                    onBundleSort={onBundleSort}
                    enableSort={enableSort}
                    toggleBundleSorting={toggleBundleSorting}
                    onBundleSortSubmit={onBundleSortSubmit}
                />
            )}

            <BundleCreateModal
                open={modal.open && modal.bundleId === null}
                eventId={eventId}
                onCreate={handleBundleCreate}
                onClose={handleModalClose}
            />
            
            <BundleEditModal
                open={modal.open && modal.bundleId !== null}
                eventId={eventId}
                bundleId={modal.bundleId}
                onUpdate={handleBundleEdit}
                onClose={handleModalClose}
            />
        </>
    )
}



const DesktopBundleList = ({ event, bundles, onBundleSelect, onBundleSort, enableSort, toggleBundleSorting, onBundleSortSubmit }) => (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <HeaderCell>NAME</HeaderCell>
                    <HeaderCell width={60} align='right'>ONLINE</HeaderCell>
                    <HeaderCell width={80} align='right'>IN-PERSON</HeaderCell>
                    <HeaderCell width={80} align='right'>SOLD</HeaderCell>
                    <HeaderCell></HeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {bundles.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={5} align='center'>No bundles found</TableCell>
                    </TableRow>
                )}

                {bundles.map((bundle, index) => (
                    <TableRow key={bundle.bundle_id}>
                        <TableCell>
                            <ProductTitle
                                name={bundle.bundle_name}
                                visible={bundle.visible}
                                startDate={bundle.sale_start_date}
                                endDate={bundle.sale_end_date}
                                timezone={event.time_zone}
                                qtyLeft={bundle.qty_left}
                                onEdit={() => onBundleSelect(bundle.bundle_id)}
                                accessCode={bundle.access_code}
                            />
                        </TableCell>
                        <TableCell align='right'>{formatPrice(bundle.price)}</TableCell>
                        {bundle.pos_visible ? (
                            <TableCell align='right'>{bundle.pos_price === null ? formatPrice(bundle.price) : formatPrice(bundle.pos_price)}</TableCell>
                        ) : (
                            <TableCell align='right'>n/a</TableCell>
                        )}
                        <TableCell align='right'>{bundle.qty_sold} / {bundle.total_available}</TableCell>

                        {enableSort === true ? (
                            <TableCell align='right' width={120}>
                                <ArrowButton disabled={index === 0} onClick={() => onBundleSort(bundle.bundle_id, 'up')}>
                                    <ArrowDropUpIcon />
                                </ArrowButton>
                                <ArrowButton disabled={index === bundles.length - 1} onClick={() => onBundleSort(bundle.bundle_id, 'down')}>
                                    <ArrowDropDownIcon />
                                </ArrowButton>
                            </TableCell>
                        ) : (
                            <TableCell align='right' width={120}>
                                <ActionButton onClick={() => onBundleSelect(bundle.bundle_id)}>Edit</ActionButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
            {bundles.length > 1 && (
                <TableFooter>
                    <TableRow>
                        {enableSort === true ? (
                            <TableCell align='right' colSpan={4}>
                                <CancelButton onClick={toggleBundleSorting}>Cancel</CancelButton>
                                <SaveButton onClick={onBundleSortSubmit}>Save</SaveButton>
                            </TableCell>
                        ) : (
                            <TableCell align='right' colSpan={5}>
                                <Tooltip message='Your customers will see your tickets in the order listed above. You can change the Display Order here.'>
                                    <span>
                                        <ActionButton startIcon={<SwapVertIcon />} onClick={toggleBundleSorting}>Set Display Order</ActionButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                        )}
                    </TableRow>
                </TableFooter>
            )}
        </Table>
    </TableContainer>
);


const MobileBundleList = ({ event, bundles, onBundleSelect, onBundleSort, enableSort, toggleBundleSorting, onBundleSortSubmit }) => (
    <Box>
        {bundles.length === 0 && (
            <Box height={128} display='flex' justifyContent='center' alignItems='center'>
                <Typography>No bundles found</Typography>
            </Box>
        )}
        {bundles.map((bundle, index) => (
            <MobileListItem key={bundle.bundle_id}>
                <ProductTitle
                    name={bundle.bundle_name}
                    visible={bundle.visible}
                    startDate={bundle.sale_start_date}
                    endDate={bundle.sale_end_date}
                    timezone={event.time_zone}
                    qtyLeft={bundle.qty_left}
                    accessCode={bundle.access_code}
                    onEdit={() => onBundleSelect(bundle.bundle_id)}
                />

                <Box display={'flex'} justifyContent={'space-between'} p={1} mt={1}>
                    <Typography>Online Price</Typography>
                    <Typography>{formatPrice(bundle.price)}</Typography>
                </Box>
                <Divider />
                <Box display={'flex'} justifyContent={'space-between'} p={1}>
                    <Typography>In-Person Price:</Typography>
                    {bundle.pos_visible
                        ? <Typography>{bundle.pos_price === null ? formatPrice(bundle.price) : formatPrice(bundle.pos_price)}</Typography>
                        : <Typography>n/a</Typography>
                    }                                        
                </Box>
                <Divider />
                <Box display={'flex'} justifyContent={'space-between'} p={1}>
                    <Typography>Sold</Typography>
                    <Typography>{bundle.qty_sold} / {bundle.total_available}</Typography>
                </Box>

                {enableSort === true ? (
                    <Box display='flex' justifyContent={'flex-end'} alignItems={'flex-end'} pt={2}>
                        <ArrowButton disabled={index === 0} onClick={() => onBundleSort(bundle.bundle_id, 'up')}>
                            <ArrowDropUpIcon />
                        </ArrowButton>
                        <ArrowButton disabled={index === bundles.length - 1} onClick={() => onBundleSort(bundle.bundle_id, 'down')}>
                            <ArrowDropDownIcon />
                        </ArrowButton>
                    </Box>
                ) : (
                    <Box display='flex' justifyContent={'flex-end'} alignItems={'flex-end'} pt={2}>
                        <ActionButton style={{ marginLeft: 8 }} onClick={() => onBundleSelect(bundle.bundle_id)}>Edit</ActionButton>
                    </Box>
                )}
            </MobileListItem>
        ))}
        {(bundles.length > 1 && enableSort === true) && (
            <Box display='flex' justifyContent={'flex-end'} py={2}>
                <CancelButton onClick={toggleBundleSorting}>Cancel</CancelButton>
                <SaveButton onClick={onBundleSortSubmit}>Save</SaveButton>
            </Box>
        )}

        {(bundles.length > 1 && enableSort === false) && (
            <Box display='flex' justifyContent={'flex-end'} py={2}>
                <Tooltip message='Your customers will see your tickets in the order listed above. You can change the Display Order here.'>
                    <span>
                        <ActionButton startIcon={<SwapVertIcon />} onClick={toggleBundleSorting}>Set Display Order</ActionButton>
                    </span>
                </Tooltip>
            </Box>
        )}
    </Box>
);


const MobileListItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));


const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1)
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    marginLeft: theme.spacing(1)
}));
ArrowButton.defaultProps = { size: 'small', disableRipple: true };